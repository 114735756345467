<template>
  <div class="container-fluid multiple-tabs mt-3">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <Breadcrumb :parent_page="{title: 'التصنيفات' , path: '/categories'}" current_page="تعديل التصنيف"/>

      <button class="btn btn-publish mb-3" type="submit" @click="updateCategory">حفظ</button>

    </div>
    <el-card v-if="!loading">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="التصنيف" name="details">
          <Details :isEditable="true" :category-details="category"  @update="handleOnChange"/>
        </el-tab-pane>
        <el-tab-pane label="محركات البحث" name="seo">
          <SEO :seo-data="category" @update="handleOnChange"/>
        </el-tab-pane>
        <el-tab-pane label="منصات التواصل الاجتماعي" name="social-media">
          <SocialMediaTab :social-data="category" @update="handleOnChange"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <Loader v-else />
  </div>

</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import SEO from "@/views/categories/components/SEO";
import SocialMediaTab from "@/views/categories/components/socialMedia";
import Details from "@/views/categories/components/details";
import { editCategory, getCategory} from "@/services/categories";
import {useToast} from "vue-toastification";
import Loader from "@/components/Loader";

export default {
  name      : "Edit_Category",
  components: {
    Loader,
    Details,
    SocialMediaTab,
    SEO,
    Breadcrumb
  },
  data()
  {
    return {
      toast: useToast(),
      activeTab: 'details',
      loading: false,
      category: {
        name: '',
        color: '',
        parent_id: '',
        description: '',
        image: '',
        is_active: false,
        seo_name: '',
        seo_title: '',
        seo_description: '',
        social_title: '',
        social_description: '',
        social_image: '',
        show_in_nav: false,
        show_in_home: false,
      }
    }
  },
  mounted() {
    this.fetchCategoryData();
  },
  methods: {
    fetchCategoryData(){
      this.loading = true
      getCategory(this.$route.params.id)
      .then(res=> {
        this.loading = false;
        console.log(res.data)
        res.data.seo_title = res.data.metas?.seo_title || ''
        res.data.seo_description = res.data.metas?.seo_description || ''
        res.data.social_title = res.data.metas?.social_title || ''
        res.data.social_description = res.data.metas?.social_description || ''
        res.data.social_image = res.data.metas?.social_image || ''
        this.category = res.data
      })
    },
    handleOnChange(key, val)
    {
      this.category = {
        ...this.category,
        [key]: val
      }
    },
    updateCategory() {
      editCategory(this.$route.params.id,this.category)
          .then(() => {
            this.$router.push('/categories')
            this.toast.success("تم تعديل التصنيف بنجاح", {
              position: "top-center",
            });
          })
          .catch((e)=> {
            const errorsList = [...Object.values(e.response.data.errors)]
            this.toast.error(errorsList[0][0])
          })
    }
  }
}
</script>

<style lang="scss">
.el-tabs__nav {
  float: right;
}


[dir=rtl] .el-dialog__headerbtn {
  right: unset;
  left: 20px;
}
</style>
